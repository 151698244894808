.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
}
.modal {
    background: #1C1C21;
    border: 1px solid rgba(76, 76, 255, 0.2);
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 40px;
    margin: auto;
    z-index: 1200;
    width: 720px;
    position: relative;
    animation-duration: 0.5s;
    animation-name: fade;
}
@keyframes fade {
    from { opacity: 0; }
    to { opacity: 1; }
}
.close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}