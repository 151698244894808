* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}
ul, ul li {
  list-style: none;
}
.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.constructor-element {
  max-width: calc(100% - 40px) !important;
}