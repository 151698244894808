.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}
.orders {
    height: 924px;
    width: 50%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}
.orders::-webkit-scrollbar {
    width: 8px;
}
.orders::-webkit-scrollbar-track {
    background: #2F2F37;
}
.orders::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}
.info {
    width: 50%;
}
.numbers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
}
.numbers_block {
    width: calc(50% - 20px);
}
.numbers_items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 321px;
    margin-left: -25px;
}
.numbers_item {
    margin-left: 25px;
}
.numbers_blue p {
    color: #00CCCC;
}
.result {}
.result_text {
    text-shadow: 0px 0px 16px rgba(51, 51, 255, 0.25), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 4px 32px rgba(51, 51, 255, 0.5);
}

.loading {
    margin-top: 100px;
    width: 100%;
    text-align: center;
}
.loading a {
    color: #ffffff;
    font-size: 16px;
}
.loading a:hover {
    text-decoration: none;
}