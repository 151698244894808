.header {
    background: #1C1C21;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
.header_blocks {
    position: relative;
    display: flex;
    justify-content: space-between;
}
.logo {
    position: absolute;
    width: 100%;
    text-align: center;
}
.left_btns {
    display: flex;
}
.btn {
    display: flex;
    z-index: 2;
    color: #8585AD;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s;
}
.btn svg path {
    transition: all 0.3s;
}
.btn:last-child {
    margin-right: 0px;
}
.btn_active, .btn:hover {
    color: #F2F2F3;
}
.btn_active svg path, .btn:hover svg path {
    fill: #ffffff;
}
