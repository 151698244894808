.checkout {
    text-align: center;
}
.done {
    background-image: url("../../images/done.svg");
    width: 107px;
    height: 102px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.done svg {
    width: 40px;
    height: 40px;
}
