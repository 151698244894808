.section {
    width: calc(50% - 20px);
}
.content {
    max-height: 520px;
    overflow-y: scroll;
    padding-left: 0px;
    margin: 0px;
}
.content::-webkit-scrollbar {
    width: 8px;
}
.content::-webkit-scrollbar-track {
    background: #2F2F37;
}
.content::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}
.item:first-child {
    margin-top: 0px !important;
}
.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.price {
    display: flex;
    align-items: center;
}
.price svg {
    width: 36px;
    height: 36px;
}
.drag {
    cursor: move;
}
.items {
    transition: all 0.3s;
    height: 700px;
}
.active {
    opacity: 0.3;
}
.none {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.05);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}