.main {
    max-height: calc(100% - 88px);
}
.blocks {
    display: flex;
    justify-content: space-between;
}
.loading {
    margin-top: 100px;
    width: 100%;
    text-align: center;
}
.loading a {
    color: #ffffff;
    font-size: 16px;
}
.loading a:hover {
    text-decoration: none;
}
.link {
    color: #8585AD !important;
}