.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 1100;
    animation-duration: 0.5s;
    animation-name: fade;
}
@keyframes fade {
    from { opacity: 0; }
    to { opacity: 1; }
}