.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.sidebar {
    width: 320px;
    margin-top: 120px;
}
.sidebar ul {
    padding-left: 0px;
    margin: 0;
}
.sidebar ul li a {
    text-decoration: none;
    height: 64px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    transition: all 0.3s;
}
.sidebar ul li a.active, .sidebar ul li a:hover {
    color: #ffffff;
}
.content {
    width: calc(100% - 320px);
    padding-left: 60px;
}
.info {
    opacity: 0.4;
}
.setup {
    width: 480px;
    margin-top: 120px;
}
.setup_buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}